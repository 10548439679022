var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        version: "1.1",
        width: _vm.svgWidth + "px",
        height: _vm.svgHeight + "px",
        viewBox: `0 0 ${_vm.svgWidth} ${_vm.svgHeight}`,
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            transform: _vm.degree
              ? `rotate(${_vm.degree}, ${_vm.svgWidth / 2}, ${
                  _vm.svgHeight / 2
                })`
              : null,
          },
        },
        [
          _vm.relAtoB.relationship !== "" || _vm.showPlaceholder
            ? _c("g", [
                _c(
                  "marker",
                  {
                    attrs: {
                      id: _vm.relAtoB.id,
                      viewBox: "-5 -5 10 10",
                      orient: "auto",
                      markerUnits: "userSpaceOnUse",
                      markerWidth: "10",
                      markerHeight: "10",
                    },
                  },
                  [
                    _c("polygon", {
                      attrs: {
                        points: "-5,-5 5,0 -5,5 -2.5,0",
                        fill: _vm.relAtoB.color,
                        stroke: _vm.relAtoB.color,
                      },
                    }),
                  ]
                ),
                _c("line", {
                  attrs: {
                    x1: "15",
                    y1: _vm.svgHeight / 2 - 5,
                    x2: _vm.lineLength - 5,
                    y2: _vm.svgHeight / 2 - 5,
                    stroke: _vm.relAtoB.color,
                    "stroke-width": "1.5",
                    "marker-end": `url(#${_vm.relAtoB.id})`,
                  },
                }),
                _c(
                  "text",
                  {
                    attrs: {
                      x: _vm.svgWidth / 2,
                      y: _vm.svgHeight / 2 - (_vm.fontSizeVal + 8),
                      fill: "#383838",
                      "font-size": `${_vm.fontSizeVal}px`,
                      "text-anchor": "middle",
                      "dominant-baseline": "middle",
                      transform:
                        Math.abs(_vm.degree) > 90
                          ? `rotate(180, ${_vm.svgWidth / 2}, ${
                              _vm.svgHeight / 2 - (_vm.fontSizeVal + 8)
                            })`
                          : null,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.ellipsisText(_vm.relAtoB.relationship) ||
                            "A→Bの関係性"
                        ) +
                        " "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.relBtoA.relationship !== "" || _vm.showPlaceholder
            ? _c("g", [
                _c(
                  "marker",
                  {
                    attrs: {
                      id: _vm.relBtoA.id,
                      viewBox: "-5 -5 10 10",
                      orient: "auto",
                      markerUnits: "userSpaceOnUse",
                      markerWidth: "10",
                      markerHeight: "10",
                    },
                  },
                  [
                    _c("polygon", {
                      attrs: {
                        points: "-5,-5 5,0 -5,5 -2.5,0",
                        fill: _vm.relBtoA.color,
                        stroke: _vm.relBtoA.color,
                      },
                    }),
                  ]
                ),
                _c("line", {
                  attrs: {
                    x1: _vm.lineLength,
                    y1: _vm.svgHeight / 2 + 5,
                    x2: "20",
                    y2: _vm.svgHeight / 2 + 5,
                    stroke: _vm.relBtoA.color,
                    "stroke-width": "1.5",
                    "marker-end": `url(#${_vm.relBtoA.id})`,
                  },
                }),
                _c(
                  "text",
                  {
                    attrs: {
                      x: _vm.svgWidth / 2,
                      y: _vm.svgHeight / 2 + (_vm.fontSizeVal + 8),
                      fill: "#383838",
                      "font-size": `${_vm.fontSizeVal}px`,
                      "text-anchor": "middle",
                      "dominant-baseline": "middle",
                      transform:
                        Math.abs(_vm.degree) > 90
                          ? `rotate(180, ${_vm.svgWidth / 2}, ${
                              _vm.svgHeight / 2 + (_vm.fontSizeVal + 8)
                            })`
                          : null,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.ellipsisText(_vm.relBtoA.relationship) ||
                            "B→Aの関係性"
                        ) +
                        " "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }