export enum TriggerKey {
  Alt = "Alt",
  Ctrl = "Ctrl",
  Meta = "Meta",
  Shift = "Shift",
}

type TShortcutKeyMap = {
  trigger?: TriggerKey;
  keys: string[];
  callback: (params?: any) => void;
};

function handleKeydown(event: KeyboardEvent, shortcuts: TShortcutKeyMap[], params?: any): void {
  // 修飾キーを持つショートカットを先に処理
  const matchedShortcut = shortcuts.find((shortcut) => {
    const { trigger, keys } = shortcut;

    const triggerMatched =
      (trigger === TriggerKey.Alt && event.altKey) ||
      (trigger === TriggerKey.Ctrl && event.ctrlKey) ||
      (trigger === TriggerKey.Meta && event.metaKey) ||
      (trigger === TriggerKey.Shift && event.shiftKey);

    return triggerMatched && keys.includes(event.key);
  });

  if (matchedShortcut) {
    event.preventDefault();
    matchedShortcut.callback(params);
    return;
  }

  // 修飾キーなしのショートカットを次に処理
  const plainShortcut = shortcuts.find((shortcut) => {
    const { trigger, keys } = shortcut;
    return !trigger && keys.includes(event.key);
  });

  if (plainShortcut) {
    event.preventDefault();
    plainShortcut.callback(params);
  }
}

/**
 * handleShortcutKeysは、グローバルにkeydownイベントを監視し、
 * 登録されたショートカットキーに対応するコールバックを実行する。
 * 呼び出し元にイベントリスナーの削除関数を返す。
 *
 * 特定の要素のみショートカットを有効にしたい場合は、グローバルではなく、
 * テンプレート内で @keydown を使用する。
 *   例: <input ref="inputSearch" @keydown.enter.exact="onClickNext" />
 */
export function handleShortcutKeys(shortcuts: TShortcutKeyMap[], params?: any): () => void {
  const handleKeydownEvent = (event: KeyboardEvent) => handleKeydown(event, shortcuts, params);
  window.addEventListener("keydown", handleKeydownEvent);

  // 呼び出し元にイベントリスナーの削除関数を返す
  return () => {
    window.removeEventListener("keydown", handleKeydownEvent);
  };
}
