var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "character-info" },
    [
      _c("image-view-round", {
        staticClass: "icon",
        style: {
          width: `${_vm.iconSizeVal}px`,
          height: `${_vm.iconSizeVal}px`,
        },
        attrs: {
          src: _vm.icon,
          placeholder: "/img/placeholders/character.png",
        },
      }),
      _c(
        "div",
        {
          staticClass: "name ellipsis",
          style: {
            maxWidth: `${_vm.iconSizeVal}px`,
            fontSize: _vm.nameSizeVal,
          },
        },
        [_vm._v(_vm._s(_vm.name))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }