var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "dragscroll",
          rawName: "v-dragscroll:nochilddrag",
          arg: "nochilddrag",
        },
      ],
      ref: "scrollable",
      staticClass: "correlation-diagram",
    },
    [
      _c(
        "div",
        {
          class: { foundation: true, "grid-line": _vm.showGridLine },
          style: { zoom: _vm.magnification },
          attrs: { "data-dragscroll": "" },
        },
        [
          _vm._l(_vm.diagram.coordinate, function (item) {
            return _c(
              "movable",
              {
                key: item.characterId,
                ref: item.characterId,
                refInFor: true,
                staticClass: "movable",
                attrs: {
                  posTop: _vm.transformationCoordinate(item.y),
                  posLeft: _vm.transformationCoordinate(item.x),
                  grid: 100,
                  disabled: _vm.disabledEdit,
                },
                on: {
                  complete: function ($event) {
                    return _vm.moveComplete($event, item.characterId)
                  },
                },
              },
              [
                _c("character-info", {
                  attrs: {
                    character: _vm.getCharacterData(item.characterId),
                    iconSize: _vm.iconSize,
                    nameSize: "28px",
                  },
                }),
              ],
              1
            )
          }),
          _vm._l(_vm.selectedCorrelationList, function (item) {
            return _c("correlation-arrows", {
              key: item.correlationId,
              staticClass: "relationship-arrow",
              style: _vm.calcArrowsCoordinate(item.characterA, item.characterB),
              attrs: {
                relationshipAtoB: item.relationshipAtoB,
                relationshipBtoA: item.relationshipBtoA,
                distance: _vm.calcDistance(item.characterA, item.characterB),
                degree: _vm.calcDegree(item.characterA, item.characterB),
                fontSize: _vm.fontSize,
                "data-dragscroll": "",
              },
            })
          }),
        ],
        2
      ),
      _c("vue-slider", {
        staticClass: "zoom-slider",
        attrs: {
          min: 0.1,
          max: 1,
          interval: 0.01,
          width: 200,
          process: false,
          "tooltip-formatter": _vm.formatter,
        },
        model: {
          value: _vm.magnification,
          callback: function ($$v) {
            _vm.magnification = $$v
          },
          expression: "magnification",
        },
      }),
      _c(
        "div",
        { staticClass: "button-wrapper" },
        [
          _c("button-round", {
            staticClass: "show-gridline",
            attrs: {
              icon: _vm.showGridLine
                ? require("@/assets/img/icon/visibility_off.svg")
                : require("@/assets/img/icon/visibility.svg"),
              size: 30,
            },
            on: {
              click: function ($event) {
                _vm.showGridLine = !_vm.showGridLine
              },
            },
          }),
          !_vm.disabledEdit
            ? _c("button-round", {
                staticClass: "show-select-correlation",
                attrs: {
                  icon: require("@/assets/img/icon/group_add.svg"),
                  size: 45,
                },
                on: { click: _vm.showSelectCorrelation },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }