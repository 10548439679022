var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.close,
          expression: "close",
        },
      ],
      staticClass: "color-picker",
      on: { click: _vm.switchFragment },
    },
    [
      _c("div", {
        staticClass: "color-specimen",
        style: { background: _vm.transformationColorCode(_vm.selected) },
      }),
      _vm.isSelecting
        ? _c(
            "div",
            { staticClass: "list" },
            _vm._l(_vm.colorList, function (color) {
              return _c("div", {
                key: color,
                staticClass: "color-specimen space",
                style: { background: color },
                on: {
                  click: function ($event) {
                    return _vm.selectColor(color)
                  },
                },
              })
            }),
            0
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }